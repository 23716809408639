import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import Tooltip from '@mui/material/Tooltip';


// **** CUSTOM IMPORTS


import Copyright from './Copyright';


const Footer= (props) => {
    
  let navigate = useNavigate();

  
  return (
      <Box
        sx={{
          border: "1px solid #000",
          borderTop:  `1px solid #cccccc`,
          py: [3, 6],
          flexGrow:1,
          height:"100%",
          width:"100%",
          mb:"auto",
          backgroundColor:"#fff"
        }}
      >
        <Container maxWidth ={"md"} >
        <Grid container >
            <Grid item xs={6} sm={4} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("Documentation")}}
              >
                Documentation
              </Link>
            </Grid>
          
            <Grid item xs={6} sm={4} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("YouTube")}}
              >
                Training Videos
              </Link>
            </Grid>
             <Grid item xs={6} sm={3} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("FrameworkWhat")}}
              >
                About ICPipeline
              </Link>
            </Grid>
            {/* <Grid item xs={6} sm={4} sx={{textAlign:"center"}}>
                  
              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("JoinTheMailingList")}}
              >
                Join Our Mailing List
              </Link>
            </Grid> */}
            <Grid item xs={12} sx={{textAlign:"right"}}>
                  
               <Copyright mainNavClick={props.mainNavClick} sx={{ mt: 5 }}  />
            </Grid>
            <Grid item xs={12} sx={{textAlign:"right"}}>
            <Tooltip placement="top"  title="Follow Us on Twitter" enterNextDelay={300}>

              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { props.mainNavClick("Twitter")}}
              >
                <TwitterIcon sx={{color:"#00acee"}}/>
              </Link>
            </Tooltip>
            <Tooltip placement="top"  title="Join the Discussion on Discord" enterNextDelay={300}>

              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 0, mx: 1.5, float:"right"}}
                onClick={() => { props.mainNavClick("Discord")}}
              >
                <Box sx={{width:"22px"}} >
                    <img
                    src={
                    "discord-v2.svg"
                    }
                    srcSet={"discord-v2.svg?"}
                    alt="Discord"
                    loading="lazy"
                    style={{width:"100%"}}
                    />
                </Box>
              </Link>
            </Tooltip>
              <Tooltip placement="top" title="Check us out on Medium" enterNextDelay={300}>


              <Link
                color="text.primary"
                href="#"
                underline="hover"
                sx={{ my: 0, mx: 1.5, float:"right"}}
                onClick={() => { props.mainNavClick("Medium")}}
              >
                <Box sx={{width:"22px"}} >
                    <img
                    src={
                    "mediumlogo.png"
                    }
                    srcSet={"mediumlogo.png?"}
                    alt="Medium"
                    loading="lazy"
                    style={{width:"100%"}}
                    />
                </Box>
              </Link>
            </Tooltip>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }// end Footer

  export default Footer ;
  