import React, { useRef, useContext } from 'react'

import { Route, Routes,useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import smoothscroll from 'smoothscroll-polyfill';
 
import { Redirect, useLocation} from 'react-router-dom';

// **** CUSTOM IMPORTS


import FrameworkTop from './FrameworkTop';
import FrameworkWhat from './FrameworkWhat';
import FrameworkWhatItIsnt from './FrameworkWhatItIsnt';
import FrameworkHow from './FrameworkHow';
import FrameworkWhy from './FrameworkWhy';
import FrameworkRoadmap from './FrameworkRoadmap' ;

import ProductsMain from './ProductsMain' ;

import AboutTop from './AboutTop'
import AboutHelp from './AboutHelp'
import AboutWho from './AboutWho'
import AboutConsulting from './AboutConsulting'

import DocumentationGitBookWindow from './DocumentationGitBookWindow';

import DocumentationTop from './DocumentationTop' ;

import DocumentationReplicator from './DocumentationReplicator' ;
import DocumentationComplete from './DocumentationComplete' ;
import DocumentationICPM from './DocumentationICPM' ;
import DocumentationUplink from './DocumentationUplink' ;
import DocumentationWorkerdocker from './DocumentationWorkerdocker' ;

import Terms from './Terms' ;

import PilotProgramTop from './PilotProgramTop' ;

import PipeDivider from '../nav/PipeDivider' ;
import FooterMain from '../nav/FooterMain';
import MainAppBar from '../nav/MainAppBar';

import AppContext from '../nav/AppContext';

const Stage = () => {

  const myContext = useContext(AppContext);

  const refFrameworkTop = useRef();
  const refFrameworkWhat = useRef();
  const refFrameworkWhatItIsnt = useRef();
  
  const refFrameworkHow = useRef();
  const refFrameworkWhy= useRef();
  const refFrameworkRoadmap = useRef();

  const refProductsMain = useRef();
  
  const refAboutTop = useRef();
  const refAboutHelp = useRef();
  const refAboutWho = useRef();
  const refAboutConsulting = useRef();

  const refDocumentationGitBookWindow = useRef();

  const refDocumentationTop = useRef();
  const refDocumentationReplicator = useRef();
  const refDocumentationComplete = useRef();
  const refDocumentationICPM = useRef();
  const refDocumentationUplink = useRef();
  const refDocumentationWorkerdocker = useRef();

// kick off the polyfill!
smoothscroll.polyfill();



let navigate = useNavigate();
let location = useLocation();



const checkLocation =  (thisLocation) => {
  let sameLocation = true ;
  if (thisLocation != location.pathname) {
    sameLocation = false ;
    navigate (thisLocation)
    
  }
  return sameLocation
} // end checkLocation


const mainNavClick =  (whereTo) => {
  event.preventDefault();        
  console.log ("NavItems-mainNavClick - start mainNavClick");
  console.log (whereTo);
  if (whereTo == "Framework") {
    if ( checkLocation ("/framework" ) ) {
      refFrameworkTop.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      document.body.scrollIntoView("alignToTop");
    }// end if check location
    
  } else if (whereTo == "FrameworkWhat") {
    checkLocation ("/framework" );
    refFrameworkWhat.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "FrameworkWhatItIsnt") {
    checkLocation ("/framework" );
    refFrameworkWhatItIsnt.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "FrameworkHow") {
    checkLocation ("/framework" );
    refFrameworkHow.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "FrameworkWhy") {
    checkLocation ("/framework" );
    refFrameworkWhy.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "FrameworkRoadmap") { 
    checkLocation ("/framework" );
    refFrameworkRoadmap.current.scrollIntoView({ behavior: 'smooth'});
  } else if (whereTo == "About") { 
    if ( checkLocation ("/framework" ) ) {
      refAboutTop.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      document.body.scrollIntoView("alignToTop");
    }// end if check location
  } else if (whereTo == "ProductsMain") { 
    checkLocation ("/framework" );
    refProductsMain.current.scrollIntoView({ behavior: 'smooth'});
    
  } else if (whereTo == "AboutHelp") {
    checkLocation ("/framework" );
    refAboutHelp.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "AboutWho") {
    checkLocation ("/framework" );
    refAboutWho.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "AboutConsulting") {
    checkLocation ("/framework" );
    refAboutConsulting.current.scrollIntoView({ behavior: 'smooth' });

  } else if (whereTo == "Documentation") { 
    myContext.setWhereTo("load");
    //if ( checkLocation ("/documentation" ) ) {
      refDocumentationGitBookWindow.current.scrollIntoView({ behavior: 'smooth' });
    //} else {
    //  document.body.scrollIntoView("alignToTop");
    // }// end if check location
    
  } else if (whereTo == "DocumentationReplicator") {
    checkLocation ("/framework" );
    refDocumentationReplicator.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "DocumentationComplete") {
    checkLocation ("/framework" );
    refDocumentationComplete.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "DocumentationICPM") {
    checkLocation ("/framework" );
    refDocumentationICPM.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "DocumentationUplink") {
    checkLocation ("/framework" );
    refDocumentationUplink.current.scrollIntoView({ behavior: 'smooth' });
  } else if (whereTo == "DocumentationWorkerdocker") {
    checkLocation ("/framework" );
    refDocumentationWorkerdocker.current.scrollIntoView({ behavior: 'smooth' });

  } else if (whereTo == "Terms") {
    checkLocation ("/terms" );
    document.body.scrollIntoView("alignToTop");
  
  } else if (whereTo == "Pilot") {
    checkLocation ("/pilot" );
    document.body.scrollIntoView("alignToTop");
  
  } else if (whereTo == "Home") { 
  
    if ( checkLocation ("/framework" ) ) {
      refFrameworkTop.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      document.body.scrollIntoView("alignToTop");
    }// end if check location

  } else if (whereTo == "YouTube") {
  
    window.open ("https://www.youtube.com/channel/UCeQhGnqjfdwPnCJgTdBrLQg", "_blank" );

  } else if (whereTo == "JoinTheMailingList") {
  
    window.open ("https://ldiux-kaaaa-aaaah-aaoea-cai.ic0.app/", "_blank" );

  } else if (whereTo == "Dfinity") {
  
    window.open ("https://dfinity.org/", "_blank" );

  } else if (whereTo == "GitHub") {
  
    window.open ("https://github.com/icpipeline-framework/icpipeline-complete-beta", "_blank" );
  
  } else if (whereTo == "Calendly") {
  
    window.open ("https://calendly.com/icpipeline/icpipeline-quick-connect?", "_blank");

  } else if (whereTo == "Discord") {
  
    window.open ("https://discord.gg/RyBj3xEtmb", "_blank");

  } else if (whereTo == "Medium") {
  
    window.open ("https://medium.com/@icpipeline", "_blank");

  } else if (whereTo == "DiscordExplorers") {
  
    window.open ("https://discord.gg/RyBj3xEtmb", "_blank");

  } else if (whereTo == "Twitter") {
  
    window.open ("https://twitter.com/icpipeline", "_blank");
  
  } else if (whereTo == "LinkedInBeekman") {
  
    window.open ("https://linkedin.com/in/mbeekman", "_blank");

  } else if (whereTo == "LinkedInRyan") {
  
    window.open ("https://www.linkedin.com/in/daniel-ryan-a1410770", "_blank");

  } else if (whereTo == "ICArchive") {
  
    window.open ("https://icarchive.com", "_blank" );

  } else if (whereTo == "Documentation") {
  
    window.open ("https://icpipeline.gitbook.io/icpipeline-complete-documentation/", "_blank" );

  } else {
    document.body.scrollIntoView("alignToTop");
  }
  
  



} // end handle click

  // var frameworkDisplay = [
  //   <Container key={1}  ref={refFrameworkTop} sx={{pt:8, 
  //     backgroundColor:"#003D7A"}}>
  //       <PipeDivider theHeight="100px"  />
  //       <FrameworkTop mainNavClick={mainNavClick} />
  //       <PipeDivider refProp={refFrameworkWhat} />
  //       <FrameworkWhat mainNavClick={mainNavClick}  />
  //       <PipeDivider refProp={refFrameworkHow} />
  //       <FrameworkHow mainNavClick={mainNavClick} />
  //       <PipeDivider refProp={refFrameworkWhy} />
  //       <FrameworkWhy mainNavClick={mainNavClick} />
  //       <PipeDivider refProp={refFrameworkRoadmap} />
  //       <FrameworkRoadmap mainNavClick={mainNavClick} />
  //       <PipeDivider />
  //     </Container>
  // ] ; /// end frameworkDisplay 

  var frameworkDisplay = [
    <Container key={1}  ref={refFrameworkTop} sx={{pt:8, 
      backgroundColor:"#003D7A"}}>
        <PipeDivider theHeight="100px"  />
        <FrameworkTop mainNavClick={mainNavClick} />
        <PipeDivider refProp={refFrameworkWhat} />
        <FrameworkWhat mainNavClick={mainNavClick}  />
        <PipeDivider refProp={refFrameworkWhatItIsnt} />
        <FrameworkWhatItIsnt mainNavClick={mainNavClick}  />
        <PipeDivider refProp={refFrameworkHow} />
        <FrameworkHow mainNavClick={mainNavClick} />
        <PipeDivider refProp={refFrameworkWhy} />
        <FrameworkWhy mainNavClick={mainNavClick} />
        <PipeDivider refProp={refProductsMain} />
        <ProductsMain mainNavClick={mainNavClick} />

        <PipeDivider refProp={refAboutHelp} />
        <AboutHelp mainNavClick={mainNavClick}/>
        {/* <PipeDivider refProp={refAboutWho} />
        <AboutWho mainNavClick={mainNavClick}/> */}
        <PipeDivider refProp={refAboutConsulting} />
        <AboutConsulting mainNavClick={mainNavClick}/>
        <PipeDivider refProp={refDocumentationGitBookWindow} />
        <DocumentationGitBookWindow mainNavClick={mainNavClick}/>

        <PipeDivider />
      </Container>
  ] ; /// end frameworkDisplay 
  
  // var documentationDisplay = [
  //   <Container key={1}  ref={refDocumentationTop} sx={{pt:8, 
  //     backgroundColor:"#003D7A"}}>
  //     <PipeDivider theHeight="100px"  />
  //       <DocumentationTop mainNavClick={mainNavClick}/>
  //     <PipeDivider refProp={refDocumentationReplicator}  />
  //       <DocumentationReplicator mainNavClick={mainNavClick}/>
  //     <PipeDivider refProp={refDocumentationComplete}  />
  //       <DocumentationComplete mainNavClick={mainNavClick}/>
  //     <PipeDivider refProp={refDocumentationICPM}  />
  //       <DocumentationICPM mainNavClick={mainNavClick}/>
  //     <PipeDivider refProp={refDocumentationUplink}  />
  //       <DocumentationUplink mainNavClick={mainNavClick}/>
  //     <PipeDivider refProp={refDocumentationWorkerdocker}  />
  //       <DocumentationWorkerdocker mainNavClick={mainNavClick}/>
  //       <PipeDivider />
  //     </Container>
  // ] ; /// end documentationDisplay  
  
  var documentationDisplay = [
    <Container key={1}  ref={refDocumentationGitBookWindow} sx={{pt:8, 
      backgroundColor:"#003D7A"}}>
      <PipeDivider theHeight="100px"  />
        <DocumentationGitBookWindow mainNavClick={mainNavClick}/>
      <PipeDivider />
      </Container>
  ] ; /// end documentationDisplay  
  
  var aboutDisplay = [
    <Container key={1}  ref={refAboutTop} sx={{pt:8, 
      backgroundColor:"#003D7A"}}>
        <PipeDivider theHeight="100px" />
        <AboutTop mainNavClick={mainNavClick}/>
        <PipeDivider refProp={refAboutHelp} />
        <AboutHelp mainNavClick={mainNavClick}/>
        <PipeDivider refProp={refAboutWho} />
        <AboutWho mainNavClick={mainNavClick}/>
        <PipeDivider refProp={refAboutConsulting} />
        <AboutConsulting mainNavClick={mainNavClick}/>
        <PipeDivider />
      </Container>
  ] ; /// end aboutDisplay  

  var termsDisplay = [
    <Container key={1}  ref={refDocumentationTop} sx={{pt:8, 
      backgroundColor:"#003D7A"}}>
      <PipeDivider theHeight="100px"  />
        <Terms mainNavClick={mainNavClick}/>
        <PipeDivider />
      </Container>
  ] ; /// end termsDisplay  

  var pilotDisplay = [
    <Container key={1}  ref={refDocumentationTop} sx={{pt:8, 
      backgroundColor:"#003D7A"}}>
      <PipeDivider theHeight="100px"  />
        <PilotProgramTop mainNavClick={mainNavClick}/>
        <PipeDivider />
      </Container>
  ] ; /// end pilotDisplay  

  return (
    <React.Fragment>
      <MainAppBar mainNavClick={mainNavClick} />
    
            <Routes>
                <Route path="/" element={frameworkDisplay} />
                <Route path="/framework" element={frameworkDisplay} />
                <Route path="/documentation" element={documentationDisplay} />
                <Route path="/about" element={aboutDisplay} />
                <Route path="/terms" element={termsDisplay} />
                <Route path="/pilot" element={pilotDisplay} />
                
                {/* <Redirect to={defaultRoute} /> */}
            </Routes>
      <FooterMain mainNavClick={mainNavClick}  />

    </React.Fragment>
  );
} // end Stage


export default Stage;